/* Project-specific CSS goes here */
:root {
  --bg-modal: hsl(220, 5%, 18%);
}



.productLogo::before {
  content: "Telos BeaconServer";
  font-weight: 600;
  font-size: 26px;
  line-height: 26px;
  color: #ccc;
  white-space: nowrap;
}

.btn-row {
  text-align: right;
  padding: 9px 10px 9px 10px;
  margin: 12px -12px -12px -12px;
  background: hsla(0, 0%, 0%, 0.1);
  border-top: 2px groove #3a3a3a40;
}
.btn-row > * {
  margin-left: 8px;
}

.uic-btn svg {
  font-size: unset;
  vertical-align: text-top;
  margin-left: -4px;
  margin-right: 2px;
}

/*-----------------------------------------------------------------------
 Nav Customizations
-----------------------------------------------------------------------*/

/*--- Icon btns ---*/

.nav-subLink {
  display: flex;
}
.nav-subLink-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 1 auto;
  padding-right: 4px;
}

.nav-link-iconBtn {
  margin-right: unset;
  height: 100% !important;
  width: 23px !important;
  padding-left: 3px;
  color: var(--text);
  transition: opacity 90ms;
  opacity: 0;
}
.nav:not(.navCollapsed) .nav-item.active .nav-link-iconBtn {
  display: none;
}
.nav-sub-item:hover .nav-link-iconBtn {
  display: inline-block !important;
  opacity: 0.4;
}
.nav-sub-item .nav-link-iconBtn:hover {
  opacity: 0.7;
}
.nav-sub-item .nav-link-iconBtn:active {
  opacity: 1;
}

/*--- New Session ---*/

.nav-newSession {
  color: #999;
  background: #191c20;
  cursor: pointer;
}
.nav-newSession:hover {
  box-shadow: none !important;
  color: var(--COLOR-TXT-VALUE);
  font-weight: 500;
}

.nav-newSession .nav-subLink {
  display: flex;
  align-items: center;
  font-size: 16px;
  pointer-events: none;
}
.nav-newSession svg {
  height: 17px;
  transform: translateX(-2px);
}

.nav:not(.navCollapsed) .nav-item.active .nav-newSession .nav-subLink {
  padding-left: calc(var(--NAV-WIDTH) / 9);
}

/*--- Status ---*/

.nav-status {
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-top: 1px solid #111;
  border-bottom: 1px solid #111;
  background: #1113;
}
.nav:not(.mobileNavOpen) .nav-status {
  margin-bottom: 50px;
}
.nav-status table {
  font-size: 15px;
}
.nav-status table:nth-child(2) {
  margin-top: 8px;
}

.nav.navCollapsed .nav-status {
  display: none;
}

/*-----------------------------------------------------------------------
  Session
-----------------------------------------------------------------------*/

.session-header button {
  vertical-align: text-bottom;
  margin-left: 8px;
}
.session-header h1 {
  display: inline-block;
  margin-right: 24px;
}

.session-studioSend {
  margin-top: 4px;
  margin-right: 24px;
}

.status {
  display: inline-block;
  font-size: 15px;
  margin-left: 8px;
}
.status > * {
  margin-top: 6px;
  background: #9992;
  border: 1px solid #111;
  border-radius: 3px;
  padding: 2px 4px;
}

.stats-detail {
  float: right;
  margin-left: 10px;
  margin-right: -12px;
  overflow: hidden;
  white-space: nowrap;
  background: #1d1d1d;
}

.panel-body {
  overflow: hidden;
}

.participant-bottom {
  padding: 9px 11px 9px 10px;
  margin: 12px -16px -12px -12px;
  background: hsla(0, 0%, 0%, 0.1);
  border-top: 2px groove #3a3a3a40;
  border-right: 2px ridge #3a3a3a40;
  border-radius: 0 3px 0 0;
  padding-top: 8px;
  padding-bottom: 7px;
  margin-top: 6px;
  max-width: max-content;
}
/* .participant-bottom > *:not(:first-child) { */
.participant-bottom > * {
  margin: 0 8px;
}

/*-----------------------------------------------------------------------
  Statistics SideCollapse
-----------------------------------------------------------------------*/
.sessionPanel-col1 {
  display: inline-block;
}

.uic-collapsable {
  float: right;
  margin-left: -16px;
}
.uic-collapsable-title {
  cursor: pointer;
  width: 0px;
  height: 0px;
}
svg.uic-collapsable-arrow {
  color: #606064;
  transform: rotate(-180deg);
  font-size: 26px;
  transition: transform 90ms;
}
.uic-collapsable.isCollapsed .uic-collapsable-arrow {
  transform: rotate(0deg);
}
.uic-collapsable-children {
  background: hsla(0, 0%, 0%, 0.1);
  overflow: scroll;
  font-size: 14px;
  height: 331px;
  margin-top: -12px;
  padding-top: 6px;
  margin-bottom: -13px;
  margin-left: 18px;
  width: 170px;
  transition: width 120ms;
  transform: translateX(12px);
}
.uic-collapsable.isCollapsed .uic-collapsable-children {
  width: 0px;
  transition: width 120ms;
}

/*-----------------------------------------------------------------------
  Session Preview
-----------------------------------------------------------------------*/

.sessionPreview {
  min-width: 300px;
  cursor: pointer;
}

.sessionPreview:hover {
  box-shadow: 0 0 0px 2px hsla(var(--primary-h), var(--primary-s), 70%, 0.3);
}

.sessions-root a:link,
.sessions-root a:visited {
  text-decoration: none;
  color: unset;
}
/* .sessionPreview.inactive .panel-title { color: #666; } */
.sessionPreview.inactive {
  opacity: 0.5;
}

.sessionPreview .panel-body > .meter-container {
  margin-top: 2px !important;
}

.sesPrev-participants {
  display: inline-block;
  vertical-align: top;
  height: 220px;
  margin-left: 16px;
  border-radius: 4px;
  overflow: auto;
  font-size: 15px;
  /* border-left: 1px solid #aaa2; */
  /* border: 1px solid #0004; */
}
/* .sesPrev-participants .uic-table tr:not(:first-child) {
  border-top: 1px solid #8882;
} */
.sesPrev-participants .uic-table tr > *:first-child {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sesPrev-participants .uic-table tbody {
  border-radius: 10px;
}
.sesPrev-participants .meter-horizontal {
  margin-top: -8px;
}

/* .uic-table.tableRowStripes > tbody > tr:nth-child(odd) {
    background-color: hsl(0, 0%, 18%, 0.3);
} */

/*-----------------------------------------------------------------------
  Misc
-----------------------------------------------------------------------*/

.noSessionsMsg {
  margin-top: 90px;
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  opacity: 0.5;
}

.emptySessionMsg {
  margin-top: 90px;
  margin-left: calc(20px + 5vw);
  min-width: max-content;
  max-width: 400px;
  opacity: 0.55;
}


.mobileNavBtn {
  display: none !important;
}

/* SHOW LEFT NAV MENU */

.nav {
  display: block;
}
:root {
  --NAV-WIDTH: 180px;
}

/*
.mobileNavBtn {
  display: block !important;
}
*/

.MuiDataGrid-colCellTitle {
  color: rgb(194, 194, 194);
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border-bottom: 1px solid #444444 !important;
  color: #f1f1f1;
}

.MuiDataGrid-root {
  border: 1px solid #181818 !important;
}

.MuiTablePagination-toolbar {
  flex-shrink: 0;
  color: rgb(194, 194, 194);
}

.MuiDataGrid-footer {
  flex-shrink: 0;
  color: rgb(194, 194, 194);
}


.MuiCheckbox-colorPrimary.Mui-checked {
  color: #888888 !important;
}

.MuiCheckbox-colorPrimary {
  color: #888888 !important;
}

.MuiChip-sizeSmall {
  height: 18px !important;;
}

.MuiChip-outlinedPrimary {
  color: #48ACF4 !important;;
  border: 1px solid #48ACF4 !important;;
}

.MuiChip-root {
  border-radius: 4px !important;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
}


/* DEVEXPRESS */

.dx-scheduler-work-space {

  background-color: #222325 !important; 
  border: 1px solid #181818 !important;

}

.dx-scheduler-header {
  background-color: #1d1d1d !important;
  border: 1px solid#181818 !important;

}

.dx-scheduler-view-switcher.dx-tabs .dx-tab.dx-tab-selected, .dx-scheduler-view-switcher.dx-tabs .dx-tab.dx-tab-selected::before {
  background-color: #222325 !important;
}

.dx-scheduler-group-header{
  color: #96ccf3 !important;
}

.dx-overlay-shader {
  background-color: rgba(12,12,12,0.8) !important;
}

.option {
  display: flex;
  background-color: #222325 !important;
  color: rgb(194, 194, 194);
}

.option > span {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

optgroup[label] {
  color:#84acf8;
}

optgroup > option
{
  color:#f1f1f1;
}

.lnSmallLabel {
  font-size:14px; 
  color: #8b8b8b;
}

.lnSmallLabelStealth {
  font-size:12px; 
  color: #141414;
}

.lnVuGridLite .meter-label {
  transform: rotate(-90deg);
}

.lnVuGrid .meter-label {
  transform: rotate(-90deg);
}

.lnVuGrid {
  position:relative;
  zoom: normal;
}


@media only screen and (max-width: 1120px) {
  .lnVuGrid {
    position:relative;
    zoom: 0.85;
  }
}

@media only screen and (max-width: 1060px) {
  .lnVuGrid {
    position:relative;
    zoom: 0.7;
  }
}

@media only screen and (max-width: 997px) {
  .lnVuGrid {
    position:relative;
    zoom: 0.5;
  }
}

@media only screen and (max-width: 950px) {
  .lnVuGrid {
    position:relative;
    zoom: normal;
  }
}

@media only screen and (max-width: 700px) {
  .lnVuGrid {
    position:relative;
    left:-100px;
    zoom: normal;
  }
}

@media only screen and (max-width: 600px) {
  .lnVuGrid {
    position:relative;
    left:-150px;
  }
}

@media only screen and (max-width: 520px) {
    .lnVuGrid {
      position:relative;
      left:-200px;
    }
}

/* ----------- iPhone 5, 5S, 5C and 5SE ----------- */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) {
    .lnVuGrid {
      position:relative;
      left:-235px;
    }
}


/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 667px) 
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: portrait) { 
    .lnVuGrid {
      position:relative;
      left:-235px;
    }
}


/* ----------- iPhone 6+, 7+ and 8+ ----------- */
@media only screen 
  and (min-device-width: 414px) 
  and (max-device-width: 736px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .lnVuGrid {
      position:relative;
      left:-235px;
    }
}

/* ----------- iPhone X ----------- */
@media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)
  and (orientation: portrait) { 
    .lnVuGrid {
      position:relative;
      left:-235px;
    }
}


/* ----------- Google Pixel ----------- */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 3) 
  and (orientation: portrait) {
    .lnVuGrid {
      position:relative;
      left:-235px;
    }
}


/* ----------- Google Pixel XL ----------- */
@media screen 
  and (device-width: 360px) 
  and (device-height: 640px) 
  and (-webkit-device-pixel-ratio: 4) 
  and (orientation: portrait) {
    .lnVuGrid {
      position:relative;
      left:-235px;
    }
}


/* ----------- iPad 1, 2, Mini and Air, iPad 3, 4 and Pro 9.7" ----------- */
@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (orientation: portrait) 
and (-webkit-min-device-pixel-ratio: 1) {
  .lnVuGrid {
    position:relative;
    left:-35px;
  }
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid rgb(75, 75, 75);;
  -webkit-text-fill-color: #f1f1f1;
  -webkit-box-shadow: 0 0 0px 1000px rgb(43, 43, 43) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.inpWith10vw {
  width: 10vw;
}

.inpWith15vw {
  width: 15vw;
}

.inpWith20vw {
  width: 20vw;
}

.inpWith25vw {
  width: 25vw;
}

.inpWith50vw {
  width: 50vw;
}

.inpWith100 {
  width: 100px;
}

.inpWith200 {
  width: 200px;
}

.inpWith300 {
  width: 300px;
}

.uic-input-textarea {
  max-width: 100%;
  padding: 2px 8px;
  margin: 0;
  border: 1px solid rgb(75, 75, 75);
  border-radius: 3px;
  font-size: inherit;
  background: rgb(43, 43, 43);
  color: white;
  box-shadow: inset 0 0px 5px rgb(37 37 37);
  box-shadow: inset 0 1px 5px rgb(37 37 37), inset 0px 0px 0px 1px rgb(35 35 35);
  border: 1px solid rgb(68, 68, 68);
}

.iqsMain {
  
  top: -65px; 
  position: relative;
}